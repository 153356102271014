import { css, StyleSheet } from 'aphrodite/no-important'
import { FluidObject } from 'gatsby-image'
import _includes from 'lodash/includes'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Content from '../components/Content'
import Copy from '../components/Copy'
import Headline from '../components/Headline'
import Layout from '../components/layout'
import Stage from '../components/Stage'
import StyledLink from '../components/StyledLink'
import { Helmet } from 'react-helmet'

interface OfferProps {
    pageContext: {
        title: string
        additionalInfo: string
        content: {
            raw: string
        }
        stageImage: {
            fluid: FluidObject
            file: {
                fileName: string
            }
            description: string
            title: string
        }
        info: {
            info: string
        }
        price: number
        metaTags: string
        metaDescription: {
            metaDescription: string
        }
    }
}

interface ContentNodeProps {
    nodeType: string
    content: {
        nodeType: string
        value: string
        data: {
            uri: string
        }
        raw: {
            value: string
        }[]
    }[]
}

class Offer extends React.PureComponent<OfferProps> {
    render(): JSX.Element {
        const pageContext = this.props.pageContext,
            content = JSON.parse(pageContext.content.raw).content,
            title = pageContext.title,
            additionalInfo = pageContext.additionalInfo,
            stageImage = pageContext.stageImage,
            info = pageContext.info.info,
            price = pageContext.price,
            metaTags = pageContext.metaTags,
            metaDescription = pageContext.metaDescription

        return (
            <Layout activeLink={this.props['*']}>
                <Helmet
                    title={'Bride Surprise | Unser Angebot | ' + title}
                    meta={[
                        {
                            name: 'description',
                            content: metaDescription ? metaDescription : title,
                        },
                        {
                            name: 'keywords',
                            content: metaTags ? metaTags : 'Bride, Braut, Junggesellinnen, Junggesellinnenabschied',
                        },
                    ]}
                >
                    <html lang="de" />
                </Helmet>

                <Content>
                    <Stage image={stageImage} small={true} />

                    <Headline left type="h1" text={title} />

                    {content.map((node: ContentNodeProps) => {
                        if (node.nodeType === 'paragraph') {
                            const text = node.content.map(p => {
                                if (p.nodeType === 'hyperlink') {
                                    return <StyledLink linkTarget={p.data.uri}>{p.content[0].value}</StyledLink>
                                } else {
                                    return p.value
                                }
                            })
                            return <Copy>{text}</Copy>
                        } else if (_includes(node.nodeType, 'heading')) {
                            return (
                                <Headline
                                    left
                                    type={node.nodeType === 'heading-1' ? 'h1' : 'h2'}
                                    text={node.content[0].value}
                                />
                            )
                        }
                    })}

                    <Copy>
                        <ReactMarkdown className={css(styles.info)} source={info} />

                        <div>
                            <strong>Kosten</strong>: {price}
                        </div>
                    </Copy>

                    {additionalInfo && <Copy>{additionalInfo}</Copy>}
                </Content>
            </Layout>
        )
    }
}

const styles = StyleSheet.create({
    info: {
        flexDirection: 'column',
        whiteSpace: 'pre-line',
    },
})

export default Offer
